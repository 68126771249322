import React from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import Button from 'components/CustomButtons';
import { GridContainer } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';
import indexStyles from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';

const useStyles = makeStyles(indexStyles);

const AllTags = ({ pathContext }) => {
  const classes = useStyles();
  const { section, tags } = pathContext;
  if (!tags) {
    return null;
  }
  return (
    <Layout>
      <Parallax small filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <GridContainer justify="center" alignItems="center">
            <h1 className={classes.title}>
              {section.toUpperCase()} - All Tags
            </h1>
          </GridContainer>
          <p>Select below tag</p>
        </div>
      </Parallax>
      <main className={classNames(classes.main, classes.mainRaised)}>
        <div className={classNames(classes.container, classes.subtitle)}>
          {tags.map((tag, index: number) => {
            return (
              <Button
                key={index}
                size="lg"
                round
                onClick={() => {
                  navigate(`/${section}/tags/${kebabCase(tag)}`);
                }}
              >
                {tag}
              </Button>
            );
          })}
        </div>
      </main>
    </Layout>
  );
};

export default AllTags;
